<template>
    <div class="rounded-2xl bg-white text-black text-center relative">
        <div class="p-4 flex flex-wrap content-center justify-center items-center" style="height:23.3rem">
            <div class="">
                <div class="w-full h-full">
                    <div class="flex items-center justify-center">
                        <img src="/img/Rainy.svg" class="w-24">
                    </div>
                    <div class="mt-2">
                        <p class="text-xl font-bold">{{ parseFloat(currentDevice.precipitation).toFixed(2) }} mm</p>
                        <!-- <p class="text-gray-400 font-lg">
                                    <span v-if="currentDevice.precipitation >= 1 && currentDevice.precipitation < 5 ">Ringan</span>
                                    <span v-else-if="currentDevice.precipitation < 0.19">Curah Hujan Sangat Rendah</span>
                                    <span v-else-if="currentDevice.precipitation >= 5 && currentDevice.precipitation < 10 ">Sedang</span>
                                    <span v-else-if="currentDevice.precipitation >= 10 && currentDevice.precipitation < 20 ">Lebat</span>
                                    <span v-else-if="currentDevice.precipitation >= 20 ">Sangat</span>
                                    <span v-else>Tidak Hujan</span>

                                </p> -->
                        <p class="text-gray-400 font-lg">
                            <span v-if="currentDevice.precipitation >= 0.1 && currentDevice.precipitation <= 2.5">Hujan
                                Ringan</span>
                            <span
                                v-else-if="currentDevice.precipitation >= 2.6 && currentDevice.precipitation <= 5">Hujan
                                Sedang</span>
                            <span v-else-if="currentDevice.precipitation > 5">Hujan Lebat</span>
                            <span v-else>Tidak Hujan</span>
                        </p>

                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: ['currentDevice'],
    components: {
    },
}
</script>