<template>
  <div class="h-full">
    <div class="grid grid-cols-12 mt-4 rounded-2xl bg-white">
        <div class="col-span-12 md:col-span-8 h-96 w-full z-30">
            <mapData v-if="maker.length" :device="device" :maker="maker" ref="myMap" class="rounded-l-2xl "/>
        </div>
        <div class="col-span-12 md:col-span-4 bg-white rounded-r-2xl" :class="`${walthrough ? 'wlatrought' :''}`" >
            <div class="ketinggian">
                <div class="">
                    <div class="w-full p-4">
                        <div class="inline-block">
                            <p class="inline-block text-sm font-bold">Perangkat</p>

                        </div>
                    </div>
                    <div class="">
                            <div  class="text-xs relative pr-2 pl-4 mr-4 bg-white" > 
                                <div class="absolute top-2.5 left-6">
                                    <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA" ></unicon>
                                    
                                </div>

                                <input
                                v-model="keyword"
                                type="text"
                                :class="`block border border-gray-200 w-full pr-2 pl-8 px-4 rounded-lg text-xs`"
                                name="keyword"
                                ref="keyword"
                                id="keyword"
                               
                                placeholder="Pencarian" />
                            </div>
                        <div class="md:h-64 bg-white overflow-auto mt-4">
                            <div @click="setCurrentDevice(item); setMapzoom(item)" v-for="item in keyword ? filterSearch : device" :key="item.id" :class="currentDevice.id == item.id ? 'bg-gray-100 '  : '' " class="hover:bg-gray-100 flex border-b-2 p-2 pl-4 cursor-pointer border-gray-100 justify-between items-center w-100" >
                                <div class="flex items-center">
                                    <div class="mr-4">
                                        <div v-if="item.type == 'WQ'" >
                                            <img src="/img/wq.svg" alt="" class="rounded-lg">
                                        </div>
                                        <div v-if="item.type == 'level'">
                                            <img src="/img/siaga3.svg" alt="" class="rounded-lg" v-if="item.siaga_level == 3">
                                            <img src="/img/siaga4.svg" alt="" class="rounded-lg" v-if="item.siaga_level == 4">
                                            <img src="/img/siaga2.svg" alt="" class="rounded-lg" v-if="item.siaga_level == 2">
                                            <img src="/img/siaga1.svg" alt="" class="rounded-lg" v-if="item.siaga_level == 1">
                                        </div>
                                        <div v-if="item.type == 'rain'">
                                            <img src="/img/rain.svg" alt="" class="rounded-lg">
                                        </div>
                                        <div v-if="item.type == 'weather'">
                                            <img src="/img/weather.svg" alt="" class="rounded-lg">
                                        </div>
                                        <div v-if="item.type == 'camera'">
                                            <img src="/img/camera.svg" alt="" class="rounded-lg">
                                        </div>
                                        <div v-if="item.type == 'wavegauge'">
                                            <img src="/img/wavebuoy.svg" alt="" class="rounded-lg">
                                        </div>
                                        <div v-if="item.type == 'wavebuoy'">
                                            <img src="/img/wavebuoy.svg" alt="" class="rounded-lg">
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-xs font-bold">{{item.name}}</p>
                                        <span style="font-size:10px" class="text-gray-400" >
                                            <span v-if="item.status_device == 'Aktif'">{{parseDate(item.last_update,'HH:mm')}} WIB </span>
                                             <span v-else>{{item.terakhir_update}}</span>
                                            - 
                                            <span v-if="item.type == 'level'">
                                                {{parseFloat(item.real_ketinggian * 100).toFixed(2)}} cm
                                            </span>

                                            <span v-else-if="item.type == 'WQ'">
                                                {{parseFloat(item.pH).toFixed(2)}}
                                            </span>

                                             <span v-else-if="item.type == 'rain' || item.type == 'weather'">
                                                {{parseFloat(item.precipitation).toFixed(2)}} mm
                                            </span>
                                        </span>
                                     
                                    </div>
                                </div>
                                <div>
                                    <div v-if="item.status_device == 'Aktif'" >
                                        <div class="flex items-center" v-if="item.persentase_battery >= 80" >
                                            <span class="font-semibold" style="font-size:10px !important">{{item.persentase_battery}}%</span> <img src="/img/battre100.svg" alt=""> 
                                        </div>
                                        <div class="flex items-center" v-if="item.persentase_battery < 80 && item.persentase_battery > 50">
                                            <span  class="font-semibold" style="font-size:10px !important">{{item.persentase_battery}}%</span> <img src="/img/battre75.svg" alt=""> 
                                        </div>
                                        <div class="flex items-center" v-if="item.persentase_battery <= 50 && item.persentase_battery >= 40">
                                           <span  class="font-semibold" style="font-size:10px !important">{{item.persentase_battery}}%</span> <img src="/img/battre50.svg" alt=""> 

                                        </div>
                                        <div class="flex items-center" v-if="item.persentase_battery < 40 && item.persentase_battery > 0">
                                            <span class="font-semibold" style="font-size:10px !important">{{item.persentase_battery}}%</span> <img src="/img/battre25.svg" alt=""> 

                                        </div>
                                        <div class="flex items-center" v-if="item.persentase_battery <= 0" >
                                            <span class="font-semibold" style="font-size:10px !important">{{item.persentase_battery}}%</span> <img src="/img/battre0.svg" alt=""> 

                                        </div>
                                    </div>
                                    <span v-if="item.status_device == 'Mati'" class="rounded-full text-white px-4 py-1" style="font-size:10px" :class="`${item.status_device == 'Mati' ? ' bg-red-600' : ' bg-green-500 '}`">{{item.status_device}}</span>
                                </div>
                            </div>
                              
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="grid grid-cols-12 mt-4 gap-4" v-if="currentDevice">
        <div class="col-span-12 md:col-span-8 rounded-2xl bg-white ">
            <div class="px-8 py-4 border-b-2 border-gray-100">
                <div v-if="currentDevice.type == 'level'">   
                    <p class="text-base font-bold">Ketinggian Air {{currentDevice.name}}</p>
                    <div class="text-gray-400 text-xs">
                        (<text-level :item="currentDevice" />) 
                         Berdasarkan hari ini jam {{parseDate(currentDevice.last_update,'HH.mm')}}
                    </div>
                  
                </div>
                <div v-if="currentDevice.type == 'WQ'">   
                    <p class="text-base font-bold">Kadar Asam Air {{currentDevice.name}}</p>
                    <div class="text-gray-400 text-xs">
                        (<text-ph :item="currentDevice" class="text-gray-400 text-xs"/>)
                        Berdasarkan hari ini jam {{parseDate(currentDevice.last_update,'HH.mm')}}
                    </div>
                 
                </div>

                <div v-if="currentDevice.type == 'rain' || currentDevice.type == 'weather'">   
                    <p class="text-base font-bold">Intensitas Hujan {{currentDevice.name}}</p>
                    <text-rain :item="currentDevice" class="text-gray-400 text-xs"/>
                </div>

            </div>
            <div class="p-4">
                <div id="chartdashboardline"></div>
            </div>
        </div>
        <div class="col-span-12 md:col-span-4 ">
           
           <levelBagan v-if="currentDevice.type == 'level'" :currentDevice="currentDevice" />
           <weatherBagan v-else-if="currentDevice.type == 'weather'" :currentDevice="currentDevice" />
           <rainBagan v-else-if="currentDevice.type == 'rain'" :currentDevice="currentDevice" />
           <qualityBagan v-else-if="currentDevice.type == 'WQ'" :currentDevice="currentDevice" />
            
        </div>
    </div>

    <div class="mt-4 bg-white rounded-2xl " v-if="currentDevice && Object.keys(currentDevice).length > 0">
        <div class="px-8 py-4 border-b-2 border-gray-200 flex justify-between items-center ">
            <p>Rata Rata {{currentDevice.name}}</p>
            <div class="group inline-block relative cursor-pointer" style="min-width:200px">
                <div class="flex flex-wrap content-center items-center justify-end" >
                    <button class="flex flex-wrap content-center items-center justify-end mr-2"  @click="openDialog()">
                    <div class="text-right">
                         <div class="flex items-center rounded-2xl border border-gray-100 px-4 py-2" v-if="filter == 'week'">
                            <img src="/img/mingguan.svg" class="mr-2" alt="">
                            <span class="text-sm">Mingguan</span>
                        </div>
                        <div class="flex items-center rounded-2xl border border-gray-100 px-4 py-2" v-else-if="filter == 'bulan'"> 
                            <img src="/img/bulanan.svg" class="mr-2" alt="">

                            <span class="text-sm">Bulanan</span>
                        </div>
                        <div class="flex items-center rounded-2xl border border-gray-100 px-4 py-2" v-else-if="filter == 'kuartal'">
                            <img src="/img/kuartal.svg" class="mr-2" alt="">

                            <span class="text-sm">Kuartal</span>
                        </div>
                        <div class="flex items-center rounded-2xl border border-gray-100 px-4 py-2" v-else-if="filter == 'year'"> 
                            <img src="/img/tahunan.svg" class="mr-2" alt="">

                            <span class="text-sm">Tahunan</span>
                        </div>
                    </div>
                    </button>
                    <!-- <div class="border-l-2 border-gray-200 pl-2">
                        <unicon name="signout" class="text-lg cursor-pointer" fill="gray" @click="logout()"></unicon>
                    </div> -->
                </div>
                <div
                    class="shadow-lg rounded-2xl transform scale-100 text-black absolute
                transition duration-150 ease-in-out origin-top w-full
                bg-white flex-none
                " v-if="dialog" style="z-index:1000000!important"
                >
                    <div class="p-4 border-b-2 border-gray-200">
                        <p class="text-sm font-bold">Option</p>
                    </div>

                    <div class="p-4">
                        <div class="flex items-center" @click="changeFilter('week')">
                            <img src="/img/mingguan.svg" class="mr-2" alt="">
                            <span class="text-sm">Mingguan</span>
                        </div>
                        <div class="flex items-center mt-4" @click="changeFilter('bulan')"> 
                            <img src="/img/bulanan.svg" class="mr-2" alt="">

                            <span class="text-sm">Bulanan</span>
                        </div>
                        <div class="flex items-center mt-4" @click="changeFilter('kuartal')">
                            <img src="/img/kuartal.svg" class="mr-2" alt="">

                            <span class="text-sm">Kuartal</span>
                        </div>
                        <div class="flex items-center mt-4" @click="changeFilter('year')"> 
                            <img src="/img/tahunan.svg" class="mr-2" alt="">

                            <span class="text-sm">Tahunan</span>
                        </div>
                    </div>
               
                </div>
            </div>
        </div>

        <div class="p-4">
            <div id="chartBarDashboard"></div>
        </div>
    </div>
    <div v-if="walthrough" class="hidden md:block">
        <div class="fixed inset-0 z-50 opacity-50 bg-black" style="z-index:110000 !important"></div>
        <div style="z-index:110002 !important" class="absolute top-0 right-0 h-full w-full">
            <div class="w-full h-screen relative" @click="setWalthrough(false)"> 
                <div class="absolute " style="right:26rem; top:28rem">
                    <div class="relative w-64">
                        <div class="bg-white rounded-2xl p-4 w-64">
                            <div class="text-sm font-bold">
                                Silakan pilih perangkat,
                                <br>
                                Untuk menampilkan data di dashboard

                                <br>
                                <button class="px-4 py-2 bg-green-500 text-xs text-white rounded-2xl mt-4">Close</button>
                            </div>
                        </div>
                        <div style="
                            clip-path: polygon(100% 50%, 0 0, 0 100%);
                            " class="bg-white h-8 w-8 absolute top-8 -right-4">
                        </div>
                    </div>  
                </div>
              
             
            </div>
        </div>
   
    </div>
 
  </div>
  
</template>
<style scoped>
    @media only screen and (min-width: 600px) {
    /* For mobile phones: */
        .wlatrought {
            z-index:110001 !important
        }
        .ketinggian {
            height: 100px !important;
        }
    }
</style>
<script>
var chartLine,chartBarDashboard
import mapData from '../map.vue'
import ApexCharts from 'apexcharts'
import { mapGetters,mapActions } from "vuex"
import { latLng } from 'leaflet';
import optionChart from './optionChart'
import globalMixin from '@/mixin/global'
import textLevel from './textLevel.vue'
import textRain from './textRain.vue'
import textPh from './textPh.vue'
import levelBagan from './levelBagan.vue'
import weatherBagan from './weatherBagan.vue'
import qualityBagan from './qualityBagan.vue'
import rainBagan from './rainBagan.vue'
import optionsBar from './optionsBar'
export default {
    data: () => ({
            device:[],
            maker:[],
            filterDevice:[],
            keyword:'',
            hours:0,
            minutes:0,
            seconds:0,
            dialog:false,
            loadingChart:false,
            updateChartStatus:false,
            total_user:0,
            update_series:false,
            filter:'week',
            total_reseller:0,
            total_customer:0,
            dataChart:[],
            dialog:false,
            showMap: false,
            currentMap: null,
            mapIsZoomedIn: false,

    }),
    components:{
        mapData,textLevel,textPh,textRain,levelBagan,rainBagan ,weatherBagan,qualityBagan
    },
    methods:{
        ...mapActions({
           setCurrentDevice:'device/setCurrentDevice',
           setWalthrough:'data/setWalthrough',
        }),
        setMapzoom(item) {
            this.currentMap = item;
            // this.$nextTick(() => {
            //     if (this.$refs.myMap) {
            //         this.$refs.myMap.flyToMarker(item.latitude, item.longitude);
            //     } else {
            //         console.error('myMap is not defined');
            //     }
            // });
            this.$nextTick(() => {
            if (this.$refs.myMap) {
                // Cek apakah sudah zoomed in
                    if (this.mapIsZoomedIn) {
                    // Jika sudah zoomed in, lakukan zoom out
                    this.$refs.myMap.setZoom();  // Misalnya zoom level 10
                    this.mapIsZoomedIn = false;  // Reset status zoom
                    } else {
                    // Pertama kali klik, terbangkan peta ke marker
                    this.$refs.myMap.flyToMarker(item.latitude, item.longitude);  // level zoom 15
                    this.mapIsZoomedIn = true;  // Set status zoom in
                    }
                } else {
                    console.error('myMap is not defined');
                }
            });
        },
        openDialog() {
            this.dialog = !this.dialog
        },
        getDevice() {
            let customer_id = this.customer ? this.customer.id : ''
            console.log("...........", customer_id, ".........")
            this.axios.get('v1/device?status=1&customer_id='+customer_id,this.config)
            .then(async (ress) => {
                this.device = ress.data.rows
               await this.device.forEach(element => {
                let image_url = ''

                if(element.type == 'WQ'){
                    image_url = '/img/map_wq.svg'
                } else if(element.type == 'level'){
                    if(element.siaga_level == 1) {
                    image_url = '/img/map_siaga1.svg'
                    }
                    if(element.siaga_level == 2) {
                    image_url = '/img/map_siaga2.svg'
                    }
                    if(element.siaga_level == 3) {
                    image_url = '/img/map_siaga3.svg'
                    }
                    if(element.siaga_level == 4) {
                    image_url = '/img/map_siaga4.svg'
                    }
                } else if(element.type == 'rain'){
                    image_url = '/img/rain_map.svg'
                }else if(element.type == 'weather'){
                    image_url = '/img/weather_map.svg'
                }else if(element.type == 'wavebuoy'){
                    image_url = '/img/wavebuoy.svg'
                }else if(element.type == 'wavegauge'){
                    image_url = '/img/wavebuoy.svg'
                }else if(element.type == 'camera'){
                    image_url = '/img/camera.svg'
                }

                this.maker.push({lokasi:latLng(element.latitude,element.longitude),name:element.name,level:element.level,
                last_update:element.last_update,
                siaga_1:element.siaga_1,
                siaga_2:element.siaga_2,
                siaga_3:element.siaga_3,
                siaga_4:element.siaga_4,
                real_ketinggian:element.real_ketinggian,
                debit:element.debit,
                elevasi_muka_air:element.elevasi_muka_air,
                type:element.type,
                pH:element.pH,
                id:element.id,
                icon_map:image_url
                })
                });
            })
             .catch((err) => {
                 console.log(err)
                 if (err.response) {
                    this.errorResponse(err)
                     
                 }
            })
        },

        async getChartLineData() {
            if (this.currentDevice) {
                await this.axios.get('v2/device/detail/data/' + this.currentDevice.id,this.config)
                .then((ress) => {
                    this.dataChart = ress.data
                })
                .catch((err) => {
                    this.errorResponse(err)
                })
            }
         
        },
        async updateChart(data) {
         if (data.id == this.currentDevice.id) {
              this.setCurrentDevice(data)
                let new_data = {}
                if (this.currentDevice.type == 'level') {
                    new_data = this.dataChart.dataReal
                    new_data.push({
                        x: new Date(data.last_update).getTime(),
                        h: parseFloat(data.elevasi_muka_air).toFixed(2),
                            y: parseFloat(data.real_ketinggian * 100).toFixed(2),
                            debit: parseFloat(data.debit).toFixed(4)
                    })
                } else if(this.currentDevice.type == 'WQ') {
                    new_data = this.dataChart.dataPh
                     new_data.push({
                        x: new Date(data.last_update).getTime(),
                        y: parseFloat(data.pH).toFixed(2),
                    })
                } else if(this.currentDevice.type == 'rain' || this.currentDevice.type == 'weather' ) {
                    new_data = this.dataChart.precipitation

                     new_data.push({
                        x: new Date(data.last_update).getTime(),
                        y: data.precipitation,
                    })
                }
               

                await chartLine.updateSeries([{data:new_data}])
             }
        },

        async setChartLine() {
            if (this.currentDevice.type == 'level') {
                await this.$set(this.optionsChart,'series',[{name:'Ketinggian Air',data:this.dataChart.dataReal}])
                 await this.$set(this.optionsChart,'tooltip',{
                marker: {
                    show: false,
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return `<div class="p-4 rounded-2xl">
                    <span class="text-green-400 font-bold"> h :${series[seriesIndex][dataPointIndex]} cm </span>
                      
                      <br>
                      <span class="mt-2 text-blue-400 font-bold">Elevasi Muka Air : ${w.config.series[seriesIndex].data[dataPointIndex].h ? w.config.series[seriesIndex].data[dataPointIndex].h : ''} m</span>
                    
                      <br>
                      <span class="mt-2 text-yellow-400 font-bold"> Q :${w.config.series[seriesIndex].data[dataPointIndex].debit ? w.config.series[seriesIndex].data[dataPointIndex].debit : ''} m3/dt </span>
                      </div>`
                },
                x: {
                    format: 'y-MM-dd HH:mm:ss',
                }
            
            })
            } else if(this.currentDevice.type == 'WQ') {
                await this.$set(this.optionsChart,'series',[{name:'pH Air',data:this.dataChart.dataPh}])
                await this.$set(this.optionsChart,'tooltip',{
                    marker: {
                        show: false,
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return `<div class="p-4 rounded-2xl">
                        <span class="font-bold">${series[seriesIndex][dataPointIndex]} pH</span>
                        </div>`
                    },
                    x: {
                        format: 'y-MM-dd HH:mm:ss',
                    }
                
                })

                await this.$set(this.optionsChart,'yaxis',{})

            } else if(this.currentDevice.type == 'rain' || this.currentDevice.type == 'weather') {
                await this.$set(this.optionsChart,'series',[{name:'Cuaca',data:this.dataChart.precipitation}])
                await this.$set(this.optionsChart,'tooltip',{
                    marker: {
                        show: false,
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return `<div class="p-4 rounded-2xl">
                        <span class="font-bold">${series[seriesIndex][dataPointIndex]} mm</span>
                        </div>`
                    },
                    x: {
                        format: 'y-MM-dd HH:mm:ss',
                    }
                })
                await this.$set(this.optionsChart,'yaxis',{})
            }
            
        },

        async generateChatLine(update = ''){
            if (this.currentDevice) {
                document.querySelector("#chartdashboardline").innerHTML = ''
                await this.setChartLine()
                chartLine = new ApexCharts(document.querySelector("#chartdashboardline"), this.optionsChart);

                await chartLine.render()
            }
          
        },

        async searchData() {
            const filtered = await this.device.filter(obj => Object.values(obj).some(val => val.includes(this.keyword)));
            this.filterDevice = filtered

            console.log(this.filterDevice)
        },

        async getData() {
 
            let pH = []
            let tgl = []
            // this.select_device = this.device.find(x => x.id == this.reportDevice.id)
            await this.axios.get('v1/device_data/report/'+this.reportDevice.id+'?filter=' + this.reportDevice.filter,this.config)
            .then(async (ress) => {
               
               await ress.data.data.forEach(element => {
               
                   pH.push(parseFloat(element.pH).toFixed(2))
                   tgl.push(element.tgl)
               });

                await this.$set(this.optionsBar.chart,'events', {
                    animationEnd: async function (chartContext, options) {
                             let base = await chart.dataURI();
                            imageBar = base.imgURI
                        }
                })

                // set data
                await this.$set( this.optionsBar,'series',[
                {name:'pH Air',
                    data:pH
                },
                ])
                await this.$set( this.optionsBar,'labels',tgl)

            
            })
            if (this.update_series == false) {
                chart = new ApexCharts(document.querySelector("#chart"), this.optionsBar);
             
                chart.render();
            
            } else {
                // chart.updateSeries([{data:real_ketinggian},{data:elevasi_muka_air},{data:debit}])
                chart.updateOptions(this.optionsBar)
               
            }
            await this.getDeviceData()

            this.update_series = true
        },

        async getAverage() {
            let pH = []
            let tgl = []
            if (this.currentDevice) {
                 await this.axios.get('v1/device_data/report/'+this.currentDevice.id+'?filter=' + this.filter,this.config)
                .then(async (ress) => {
                let title = ''
                await ress.data.data.forEach(element => {
                    if (this.currentDevice.type == 'level') {
                            pH.push(parseFloat(element.real_ketinggian * 100).toFixed(2))
                        title = 'Ketinggian Air'

                    } else if(this.currentDevice.type == 'WQ') {
                            pH.push(parseFloat(element.pH).toFixed(2))
                        title = 'pH'
                    } else if(this.currentDevice.type == 'rain' || this.currentDevice.type == 'weather') {
                            pH.push(parseFloat(element.precipitation).toFixed(2))
                        title = 'Intensitas Hujan'
                    }
                    tgl.push(element.tgl)
                });

                    // set data
                    await this.$set( this.optionsBar,'series',[
                        {name:title,
                            data:pH
                        },
                    ])
                    await this.$set( this.optionsBar,'labels',tgl)

                
                })
                if (this.update_series == false) {
                    chartBarDashboard = new ApexCharts(document.querySelector("#chartBarDashboard"), this.optionsBar);
                
                    await chartBarDashboard.render();
                
                } else {
                    // chart.updateSeries([{data:real_ketinggian},{data:elevasi_muka_air},{data:debit}])
                    await chartBarDashboard.updateOptions(this.optionsBar)
                
                }

                this.update_series = true
            }
            // this.select_device = this.device.find(x => x.id == this.reportDevice.id)
           
        },

        changeFilter(filter) {
            this.filter = filter
            this.getAverage()
        }
    },
     mixins:[globalMixin,optionChart,optionsBar],
      computed:{
          ...mapGetters({
            customer:'device/customer',
            currentDevice:'device/currentDevice',
            walthrough:'data/walthrough'
        }),
        filterSearch() {
            return this.device.filter( hero => {
            return !this.keyword ||
                hero.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            })
        }
    },
    async created() {
        await this.getDevice()
        await this.getChartLineData()
        await this.generateChatLine()
        await this.getAverage()
    },

     watch: {
    // whenever question changes, this function will run
        currentDevice: async function (newQuestion, oldQuestion) {
            console.log("currentDevice berubah:", newQuestion, oldQuestion);

            if (!newQuestion) return;
            if (newQuestion.id != oldQuestion.id || newQuestion.id !== oldQuestion.id) {
                await this.getChartLineData()
                await this.generateChatLine()
                await this.getAverage()

            }
           
        }
    },
    
    async mounted() {
        // this.showMap = true; // Atur peta untuk ditampilkan setelah komponen dirender

        this.sockets.subscribe('updatedatachart', async (data) => {
            
            this.updateChart(data)
            let findIndex = this.device.findIndex( x => x.id == data.id)
            if (findIndex >= 0) {
            let new_data = data
              this.device.splice(findIndex,1,new_data)
            }

            let findIndexMaker = this.maker.findIndex( x => x.id == data.id)
            if (findIndexMaker >= 0) {
              let new_value = this.maker[findIndexMaker]
              new_value.pH = data.pH
              new_value.precipitation = data.precipitation
              new_value.real_ketinggian = data.real_ketinggian
              new_value.elevasi_muka_air = data.elevasi_muka_air
              new_value.last_update = data.last_updateç
              new_value.debit = data.debit
              this.maker.splice(findIndexMaker,new_value)
            }
        });

    },
}
</script>
