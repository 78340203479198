<template>
    <div class="rounded-2xl bg-white text-black text-center relative">
        <div class="">
            <div class="flex justify-center items-centers w-full">
                <img src="/img/Rainy.svg" class="w-28 mt-4" v-if="currentDevice.precipitation > 0">
                <img src="/img/Sunny.svg" class="w-28 mt-4" v-if="currentDevice.precipitation <= 0">
            </div>


            <div class="mt-2">
                <p class="text-xl font-bold">{{ parseFloat(currentDevice.airTemperature).toFixed(0) }} <sup>o</sup>
                    <span class="font-semibold">C</span>
                </p>
                <p class="text-gray-400 font-lg">
                    {{ currentDevice.precipitation > 0 ? 'Hujan' : 'Cerah' }}
                </p>
            </div>
            <div class="mt-4">
                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                        <div class="flex items-center">
                            <unicon name="temperature-half" width="19px" class="mr-1" fill="#94A3B8"></unicon>
                            <p class="text-gray-400">Tekanan</p>

                        </div>
                        <p>{{ parseFloat(currentDevice.atmosphericPressure).toFixed(0) }} kPa</p>
                    </div>

                </div>

                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                        <div class="flex items-center">
                            <unicon name="wind" width="19px" class="mr-1" fill="#94A3B8"></unicon>
                            <p class="text-gray-400">Kecepatan Angin</p>

                        </div>
                        <p>{{ parseFloat(currentDevice.windSpeed).toFixed(0) }} km/h</p>
                    </div>

                </div>

                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                        <div class="flex items-center">
                            <unicon name="tear" width="19px" class="mr-1" fill="#94A3B8"></unicon>
                            <p class="text-gray-400">Kelembapan</p>
                        </div>
                        <p>{{ parseFloat(currentDevice.relativeHumidity).toFixed(2) * 100 }}%</p>
                    </div>

                </div>

                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                        <div class="flex items-center">
                            <unicon name="raindrops" width="19px" class="mr-1" fill="#94A3B8"></unicon>
                            <p class="text-gray-400">Curah Hujan</p>
                        </div>
                        <p>{{ parseFloat(currentDevice.precipitation).toFixed(2) }} mm</p>
                    </div>

                </div>
                <div class="px-8">
                    <div class="flex justify-between border-b-2 border-gray-100 py-2 ">
                        <div class="flex items-center">
                            <unicon name="compass" width="19px" class="mr-1" fill="#94A3B8"></unicon>
                            <p class="text-gray-400">Arah Angin</p>
                        </div>
                        <div class="flex items-center">
                            <unicon :name="windIcon" width="20" fill="black" />
                            <p>{{ windDirectionLabel }}</p>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['currentDevice'],
    components: {
  },
    computed: {
        windDirectionLabel() {
            const angle = parseFloat(this.currentDevice.windDirection);
            if ((angle >= 0 && angle < 22.5) || (angle >= 337.5 && angle <= 360)) return "Utara";
            if (angle >= 22.5 && angle < 67.5) return "Timur Laut";
            if (angle >= 67.5 && angle < 112.5) return "Timur";
            if (angle >= 112.5 && angle < 157.5) return "Tenggara";
            if (angle >= 157.5 && angle < 202.5) return "Selatan";
            if (angle >= 202.5 && angle < 247.5) return "Barat Daya";
            if (angle >= 247.5 && angle < 292.5) return "Barat";
            if (angle >= 292.5 && angle < 337.5) return "Barat Laut";
            return "Tidak Diketahui";
        },
        // windIcon() {
        //     const angle = parseFloat(this.currentDevice.windDirection);
        //     if ((angle >= 0 && angle < 22.5) || (angle >= 337.5 && angle <= 360)) return "fa-solid fa-arrow-up";
        //     if (angle >= 22.5 && angle < 67.5) return "fa-solid fa-arrow-up-right";
        //     if (angle >= 67.5 && angle < 112.5) return "fa-solid fa-arrow-right";
        //     if (angle >= 112.5 && angle < 157.5) return "fa-solid fa-arrow-down-right";
        //     if (angle >= 157.5 && angle < 202.5) return "fa-solid fa-arrow-down";
        //     if (angle >= 202.5 && angle < 247.5) return "fa-solid fa-arrow-down-left";
        //     if (angle >= 247.5 && angle < 292.5) return "fa-solid fa-arrow-left";
        //     if (angle >= 292.5 && angle < 337.5) return "fa-solid fa-arrow-up-left";
        //     return "fa-solid fa-question";
        // }
        windIcon() {
      const angle = parseFloat(this.currentDevice.windDirection);
      if ((angle >= 0 && angle < 22.5) || (angle >= 337.5 && angle <= 360)) return "arrow-down";
      if (angle >= 22.5 && angle < 67.5) return "arrow-down-right";
      if (angle >= 67.5 && angle < 112.5) return "arrow-left";
      if (angle >= 112.5 && angle < 157.5) return "arrow-up-right";
      if (angle >= 157.5 && angle < 202.5) return "arrow-up";
      if (angle >= 202.5 && angle < 247.5) return "arrow-up-left";
      if (angle >= 247.5 && angle < 292.5) return "arrow-right";
      if (angle >= 292.5 && angle < 337.5) return "arrow-down-left";
      return null;
    }
    }
}
</script>